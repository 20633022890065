<script>
import { SATELLITES_DATA_ADVANCED_INDICATORS_AVG, PADDOCKS_SATELLITES_DATA_ADVANCED_INDICATORS_AVG } from "@/graphql/queries"
import darkColors from '@/utils/data/darkColors.json'
import { mapState } from 'vuex'

export default {
  components: {
    apexcharts: () => import('vue-apexcharts'),
    speedometer: () => import('vue-speedometer')
  },

  data() {
    return {
      loading: false,
      chartOptions: {
        legend: {
          show: false
        },

        chart: {
          height: 350,
          id: 'chart-two',
          type: 'treemap',
        },

        title: {
          text: 'Matéria seca',
          align: 'center',
          style: {
            fontWeight: 'bold',
            color: 'rgba(0,0,0,.6)'
          }
        },

        subtitle: {
          text: '(kg/ha)',
          align: 'center',
          offsetY: 33,
          style: {
            fontSize: '0.83em',
            fontWeight: 'bold',
            color: 'rgba(0,0,0,.6)'
          }
        },

        colors: [],

        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px',
          },

          formatter: function(text, op) {
            return [text, op.value.toLocaleString(undefined, {useGrouping: true, maximumFractionDigits: 0})]
          },

          offsetY: -4
        },

        plotOptions: {
          treemap: {
            distributed: true,
            // enableShades: true,
          }
        },
      },

      series: [{
        data: []
      }],

      segments: [
        {
          name: 'Proteína bruta',
          unit: 'PB',
          code: 'pb',
          suffix: '%',
          colors: ["#e0d433", "#409640", "#0c5d0c"],
          indicators: [0, 7, 15, 25],
          data: [0, 0, 0]
        },
        {
          name: 'Energia metabólica',
          unit: 'kcal/kg',
          code: 'em',
          colors: ["#99b7b9", "#66aee7", "#005aa1"],
          indicators: [0, 1500, 2500, 4000],
          data: [0, 0, 0]
        },
        {
          name: 'Fibra detergente neutro',
          unit: 'FDN',
          code: 'fdn',
          suffix: '%',
          colors: ["#083e08", "#D5AB09", "#C4A484"],
          indicators: [0, 30, 60, 95],
          data: [0, 0, 0]
        },
        {
          name: 'Máteria seca',
          unit: 'kg/ha',
          code: 'ms_adjusted_area',
          colors: ["#f51b1b", "#eded05", "#008000"],
          indicators: [0, 1000, 3000, 6000],
          data: [0, 0, 0]
        },
      ]
    }
  },

  computed: {
    ...mapState('dashboard', ['filter'])
  },

  watch:{
    filter() {
      this.fetchSatellitesData()
      this.fetchPaddocksSatellitesData()
    }
  },

  methods: {
    async fetchSatellitesData() {
      // this.loading = true
      try{
        const filter = this.filter

        if (!filter.farmId || !filter.startAt || !filter.endAt) return
        const paddock = {}
        const where = {paddock}
        where.farm_id = { _eq: filter.farmId }
        where.captured_at = { _gte: filter.startAt, _lte: filter.endAt }
        where.type = {_eq: 'OPTICO' }
        where.info_lost = { _lte: 80 }
        if(filter.moduleId) where.paddock.ref_module_id = {_eq: filter.moduleId}
        // if(filter.moduleId) where.module_id = { _eq: filter.moduleId }
        if(filter.paddockIds && filter.paddockIds.length > 0) where.paddock_id = { _in: filter.paddockIds }

        const { data } = await this.$apollo.query({ query: SATELLITES_DATA_ADVANCED_INDICATORS_AVG, variables: { where } })
        // console.log({data})
        if (data && data.satellites_data_advanced_aggregate && data.satellites_data_advanced_aggregate.aggregate && data.satellites_data_advanced_aggregate.aggregate.avg.ms_adjusted_area ) {
          this.mountChart(data.satellites_data_advanced_aggregate)
        }else if(filter.moduleId) {
          where.paddock = {module_id: {_eq: filter.moduleId}}
          const { data } = await this.$apollo.query({ query: SATELLITES_DATA_ADVANCED_INDICATORS_AVG, variables: { where } })
          if (data && data.satellites_data_advanced_aggregate && data.satellites_data_advanced_aggregate.aggregate) {
            this.mountChart(data.satellites_data_advanced_aggregate)
          }
        }
      } catch (e) {
        console.error(e)
      }
      // this.loading = false
    },

    async fetchPaddocksSatellitesData() {
      this.loading = true
      try{
        const filter = this.filter

        if (!filter.farmId || !filter.startAt || !filter.endAt) {
          this.loading = false;
          return
        }

        const wherePaddocks = {}
        const whereSatellitesData = { info_lost: { _lte: 80 } }
        wherePaddocks.module = { farm_id: { _eq: filter.farmId } }
        whereSatellitesData.farm_id = { _eq: filter.farmId }
        whereSatellitesData.captured_at = { _gte: filter.startAt, _lte: filter.endAt }
        whereSatellitesData.type = {_eq: 'OPTICO'}
        if(filter.moduleId) {
          delete wherePaddocks.module
          wherePaddocks.ref_module_id = { _eq: filter.moduleId }
          whereSatellitesData.module_id = { _eq: filter.moduleId }
        }
        const { data } = await this.$apollo.query({ query: PADDOCKS_SATELLITES_DATA_ADVANCED_INDICATORS_AVG, variables: { wherePaddocks, whereSatellitesData } })
        // console.log({data})
        if (data && data.paddocks.length === 0) {
          delete wherePaddocks.ref_module_id
          wherePaddocks.module_id = { _eq: filter.moduleId }
          const { data } = await this.$apollo.query({ query: PADDOCKS_SATELLITES_DATA_ADVANCED_INDICATORS_AVG, variables: { wherePaddocks, whereSatellitesData } })
          const dataParsed = data.paddocks.map(item => ({
            id: item.id,
            paddockCode: item.code,
            msAdjustedAvg: item.satellites_data_advanced_aggregate.aggregate.avg.ms_adjusted_area || 0
          }))
          this.mountChartPaddocks(dataParsed)
        } else {
          const dataParsed = data.paddocks.map(item => ({
            id: item.id,
            paddockCode: item.code,
            msAdjustedAvg: item.satellites_data_advanced_aggregate.aggregate.avg.ms_adjusted_area || 0
          }))
          this.mountChartPaddocks(dataParsed)
        }
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    },

    mountChart(val) {
      // console.log({val})
      this.segments = this.segments.map(item => {
        let max = val.aggregate.max[item.code] || 0
        let avg = val.aggregate.avg[item.code] || 0
        let min = val.aggregate.min[item.code] || 0
        if (['pb', 'fdn'].indexOf(item.code) > -1) {
          max = parseFloat(max.toFixed(1))
          avg = parseFloat(avg.toFixed(1))
          min = parseFloat(min.toFixed(1))
        }
        if (['em', 'ms_adjusted_area'].indexOf(item.code) > -1) {
          max = parseInt(max.toFixed(0))
          avg = parseInt(avg.toFixed(0))
          min = parseInt(min.toFixed(0))
        }

        item.data = [max, avg, min]
        return item
      })
    },

    mountChartPaddocks(val) {
      const valParsed = val
            .map((item, index) => ({...item, index }))
            .filter(item => item.msAdjustedAvg > 0)
            .sort((a,b) => a.msAdjustedAvg > b.msAdjustedAvg ? -1 : 1)
            .map(item => ({
              x: item.paddockCode,
              y: parseInt(item.msAdjustedAvg),
              id: item.id,
              index: item.index
            }))

      let paddockFilters = null
      let selectedIndexes = null
      if (this.filter.paddockIds && this.filter.paddockIds.length > 0) {
        paddockFilters = valParsed.filter(item => this.filter.paddockIds.includes(item.id))
        selectedIndexes = paddockFilters.map(i => i.index)
      }

      const colors = paddockFilters ? darkColors.colors.filter((v,i) => selectedIndexes.includes(i)) : valParsed.map(item => darkColors.colors[item.index])
      this.$set(this.chartOptions, 'colors', colors)

      const data = paddockFilters ? paddockFilters : valParsed
      this.series = [ { data } ]
    },

    dataPointSelectionHandler(event, chartContext, options){
      const index = options.dataPointIndex
      let paddockId = this.series[0].data[index].id
      if (this.filter.paddockIds && this.filter.paddockIds.length === 1) {
        this.filter.paddockIds = []
      } else {
        this.filter.paddockIds = [paddockId]
      }
      this.$store.dispatch('dashboard/changeFilter', this.filter)
    }
  }
}
</script>

<template>
  <v-card :loading="loading">
    <v-card-text>
      <v-row>
        
          
            <v-col v-for="segment in segments" :key="segment.name" cols="12" xs="12" sm="6" md="2">
              <v-card>
                <v-card-text class="text-center pa-0">
                  <h4 class="pt-1">{{ segment.name }}</h4>
                  <h5 class="pt-0">({{ segment.unit }})</h5>
                  <speedometer
                    v-for="(data,index) in segment.data"
                    :key="'speedometer' + segment.name + index"
                    :value="data"
                    :max-segment-labels="0"
                    :segments="3"
                    :needle-height-ratio="0.6"
                    :ring-width="30"
                    :width="170"
                    :height="120"
                    :segment-colors='segment.colors'
                    :max-value="segment.indicators[segment.indicators.length - 1]"
                    :custom-segment-stops="segment.indicators"
                    :current-value-text="`${['Máx', 'Méd', 'Mín'][index]}: ${data.toLocaleString()}${segment.suffix || ''}`"
                    needle-color="#d4d4d4"
                  />
                </v-card-text>
              </v-card>
            </v-col>
          
        
        <v-col cols="12" sm="12" md="4">
          <apexcharts v-if="!loading" height="428" :options="chartOptions" :series="series" @dataPointSelection="dataPointSelectionHandler"/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style lang="scss">

</style>
